#corrective-action-print-modal, #corrective-action-print-area {
  .modal-dialog {
    .modal-header {
      span.status {
        //color: dark
      }
    }

    .modal-content {
      width: 800px;

      //#reset-dismissed-infractions {
      //	position: absolute;
      //	right: 5%;
      //}
    }
  }

  .previous-disciplines {
    column-count: 3;

    > li {
      break-inside: avoid;
    }
  }

  table.discipline-info {
    span.label {
      font-weight: bold;
    }

    td {
      border: solid darkgray thin;
      width: 50%;
    }

  }

  .react-datepicker-wrapper {
    max-width: 50%;
  }

  .chevron-background {
    filter: drop-shadow(.5px 0px 0px black) drop-shadow(-.5px 0px 0px black) drop-shadow(0px .5px 0px black) drop-shadow(0px -.5px 0px black) drop-shadow(.5px .5px 0px black) drop-shadow(-.5px -.5px 0px black) drop-shadow(-.5px .5px 0px black) drop-shadow(.5px -.5px 0px black);
    cursor: pointer;

    .chevron-inner {
      display: inline-block;
      position: relative;
      text-align: center;
      box-sizing: border-box;
      border: solid thin black;
      background-color: whitesmoke;
      @media print {
        background-color: white;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;

      }
      -webkit-clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 15% 50%);
      clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 15% 50%);

      &.active {
        background-color: deepskyblue;

        @media print {
          background-color: gray;
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
      }

      span.warning-step-number {
        font-size: larger;
        margin-left: 10px;
      }

      span.warning-label {
        margin-left: 10px;
      }
    }
  }


}
