@import "../../_library/assets/sass/core/components/_variables.scss";

.ske-date-picker {

	.react-datepicker-popper {
		z-index: 10;
	}

	label.ske-form-label {
		font-family: $font-family-sans-serif;
	}

	.ske-form-control {
		font-family: $font-family-sans-serif;
		font-style: normal;
		line-height: 27px;
		border-radius: 6.175px;
		cursor: pointer;
	}

}
